<template>
  <button :class="buttonClass" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      return this.disabled ? "disabled-button" : "enabled-button";
    },
  },
};
</script>

<style scoped lang="scss">
button {
  text-align: center;
  box-shadow: 0px 2px 0px 0px #0591ff1a;
  border: 1px solid $primary-color;
  border-radius: 8px;
  background-color: $primary-color;
  color: white;
  padding: 8px 15px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

.enabled-button {
  &:hover {
    background-color: #217cfc;
  }
}

.disabled-button {
  background-color: #0000000a;
  border-color: #d9d9d9;
  cursor: auto;
  color: #00000040;
}
</style>
