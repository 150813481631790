<template>
  <button>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.54233 2.94617C8.41719 2.77097 8.21514 2.66699 7.99984 2.66699C7.78454 2.66699 7.58249 2.77097 7.45735 2.94617L4.53322 7.03995L1.74964 4.81308C1.54952 4.65299 1.27536 4.62178 1.04438 4.73279C0.813402 4.8438 0.666504 5.07739 0.666504 5.33366V7.33366V7.38244V7.38245C0.666486 8.59808 0.666472 9.57791 0.770081 10.3485C0.877651 11.1486 1.10778 11.8223 1.64282 12.3573C2.17785 12.8924 2.85152 13.1225 3.65162 13.2301C4.42225 13.3337 5.40207 13.3337 6.61769 13.3337H6.61773H6.66651H9.33317H9.38195H9.38198C10.5976 13.3337 11.5774 13.3337 12.3481 13.2301C13.1482 13.1225 13.8218 12.8924 14.3569 12.3573C14.8919 11.8223 15.122 11.1486 15.2296 10.3485C15.3332 9.57791 15.3332 8.59806 15.3332 7.38242V7.33366V5.33366C15.3332 5.07739 15.1863 4.8438 14.9553 4.73279C14.7243 4.62178 14.4502 4.65299 14.25 4.81308L11.4665 7.03995L8.54233 2.94617Z"
        fill="#FAAD14"
      />
    </svg>

    <span>Upgrade Plan</span>
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  height: 32px;
  border: 1px solid var(--Colors-Base-Gold-5, #ffc53d);
  border-radius: 8px;
  box-shadow: 0px 2px 0px 0px #00000005;
  background: var(--Colors-Base-Gold-1, #fffbe6);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 16px;
  svg {
    margin-right: 8px;
  }
}
</style>
