<template>
  <div class="my-profile">
    <TitleSection :title="$t('my profile')" class="mb-4" />
    <div class="personal-inforamtion" v-if="!loader">
      <h2>{{ $t("Personal Inforamtion") }}</h2>
      <form class="" @submit.prevent="">
        <v-row>
          <v-col cols="12" md="6">
            <ListaTextfield
              :label="$t('Full Name')"
              v-model="fullName"
              :required="true"
            />
          </v-col>
          <v-col cols="12" md="6">
            <ListaTextfield
              :label="$t('Email')"
              v-model="email"
              :required="true"
            />
          </v-col>
        </v-row>
        <div class="new-password">
          <label class="mx-0">
            <input type="checkbox" v-model="createPassword" />
            {{ $t("Create New Password") }}
          </label>

          <transition name="fade">
            <div v-if="createPassword" class="password-container">
              <v-row>
                <v-col cols="12" md="4">
                  <ListaTextfield
                    label="Old Password"
                    v-model="oldPassword"
                    type="password"
                /></v-col>
                <v-col cols="12" md="4">
                  <ListaTextfield
                    :label="$t('Password')"
                    v-model="password"
                    type="password"
                /></v-col>
                <v-col cols="12" md="4">
                  <ListaTextfield
                    :label="$t('Confirm Password')"
                    v-model="confirmPassword"
                    type="password"
                /></v-col>
              </v-row>
              <div v-if="passwordMismatch">
                <span class="password-error">{{
                  $t("Passwords do not match!")
                }}</span>
              </div>
              <div v-if="oldPasswordWrong">
                <span class="password-error">{{
                  $t("The old password does not match the current password.!")
                }}</span>
              </div>
            </div>
          </transition>
        </div>
        <div class="d-flex justify-end mt-3">
          <v-btn
            class="cursor-pointer save"
            :loading="loading"
            @click="updateProfileData"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </div>
      </form>
    </div>
    <v-container v-else class="position-relative">
      <ListaProgressCircular height="450px" />
    </v-container>
    <v-dialog v-model="isDataSavedDialog" max-width="520">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn v-bind="activatorProps" class="dialog-btn"></v-btn>
      </template>
      <v-card style="border-radius: 10px; padding: 20px 24px">
        <div class="d-flex align-center justify-center">
          <div>
            <div class="dialog-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                  fill="#52C41A"
                />
              </svg>
            </div>
            <div class="dialog-success-title">Data Saved Successfully</div>
            <div class="dialog-success-description">
              All changes have been saved successfully.
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NormalBtn from "../ui/NormalBtn.vue";
import { changPassword, profile } from "@/api/profile";
import { updateProduct } from "@/api/products";
import ListaProgressCircular from "../ui/progress/ListaProgressCircular.vue";
import TitleSection from "../ui/TitleSection.vue";
import ListaTextfield from "../ui/inputs/ListaTextfield.vue";
export default {
  name: "MyProfile",
  data() {
    return {
      fullName: "",
      email: "",
      createPassword: false,
      password: null,
      oldPassword: null,
      confirmPassword: null,
      shop: {},
      loading: false,
      loader: false,
      passwordMismatch: false,
      isDataSavedDialog: false,
      oldPasswordWrong: false,
      userData: null,
    };
  },
  components: {
    NormalBtn,
    ListaProgressCircular,
    TitleSection,
    ListaTextfield,
  },
  computed: {
    ...mapGetters(["user"]),
    filteredShopData() {
      let shop_str = this.userData.shops[0].json_data;
      let shop = JSON.parse(shop_str);
      let data = Object.fromEntries(
        Object.entries(shop).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );
      return data;
    },
  },
  mounted() {
    this.loader = true;
    profile()
      .then((response) => {
        this.fullName = response.name;
        this.email = response.email;
        this.userData = response;
        this.loader = false;
      })
      .catch((error) => {
        this.loader = false;
        this.$store.dispatch("showError", error.response.data.message);
      });
  },
  methods: {
    updateProfileData() {
      this.oldPasswordWrong = false;
      if (this.password != this.confirmPassword) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
        this.loading = true;
        changPassword({
          password: this.password,
          oldPassword: this.oldPassword,
          fullName: this.fullName,
          createPassword: this.createPassword,
        })
          .then((data) => {
            this.loading = false;
            this.isDataSavedDialog = true;
            localStorage.setItem("user", JSON.stringify(data.user));
          })
          .catch((error) => {
            this.loading = false;
            this.oldPasswordWrong = true;
            this.$store.dispatch("showError", error.response.data.message);
          });
      }
    },
    formatKey(key) {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
.my-profile {
  .setting-header {
    border-bottom: 1px solid #0000000f;
    margin-bottom: 24px;
    padding-bottom: 0;
    .description {
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #000000e0;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #000000a6;
      }
    }
  }
  .personal-inforamtion {
    background: #00000005;
    padding: 20px;
    border-radius: $main-border-radius;
    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #263238;
      margin-bottom: 24px;
    }
    form {
      color: #000000e0;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      .text-field {
        margin-bottom: 24px;
        label {
          margin-bottom: 8px;
        }
        input {
          padding: 5px 12px;
          border-radius: 6px;
          border: 1px solid #00000026;
          outline: none;
          width: 100%;
          font-weight: 600;
          background: #ffffff;
        }
      }
      button.save {
        margin-top: 24px;
        padding: 8px 15px;
        color: #fff;
        background: #2c80ff;
        box-shadow: 0px 2px 0px 0px #0591ff1a;
        border-radius: 10px;
      }
    }
  }
  .new-password {
    margin-top: 24px;
  }
  .password-container {
    margin-top: 10px;
  }
  label {
    margin-right: 10px;
  }
  .password-error {
    color: red;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
