<template>
  <div class="input-wrap" :style="showError ? 'margin-bottom: 0px;' : ''">
    <label v-if="isHasLabel">
      {{ label }}
      <span v-if="required" class="required">*</span>

      <!-- for accuracy value -->
      <span
        v-if="
          accuracyValue && accuracyValue < 100 && warningType === 'accuracy'
        "
        class="text-warning accuracy-text"
      >
        accuracy of this field is not perfect (
        {{ accuracyValue }}% )
      </span>
    </label>
    <div class="input-with-icon">
      <input
        :type="type"
        :placeholder="label"
        :value="modelValue"
        @input="validateInput($event)"
        :class="[{ 'input-error': showError }]"
      />
      <div class="icon-error-container" v-if="showError">
        <TextFieldErrorIcon />
      </div>
    </div>
    <span v-if="showError" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script>
import TextFieldErrorIcon from "@/components/ui/svgs/TextFieldError.vue";

export default {
  components: {
    TextFieldErrorIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    isHasLabel: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "text",
    },
    warningType: {
      type: String,
      default: "",
    },
    accuracyValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showError: false,
      errorMessage: "",
    };
  },
  mounted() {
    // Validate on mount if required and input is empty
    if (this.required && !this.modelValue) {
      this.validateInput();
    }
  },
  methods: {
    validateInput(event) {
      const value = event?.target?.value || "";
      this.$emit("update:modelValue", value);
      if (this.required && !value.length) {
        this.showError = true;
        this.errorMessage = `this failed ${this.label.toLowerCase()} required.`;
      } else {
        this.showError = false;
        this.errorMessage = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accuracy-text {
  font-size: 13px;
  font-weight: 400;
  margin-left: 0.5rem;
}
.input-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  label {
    margin-bottom: 8px;
  }

  .input-with-icon {
    position: relative;

    input {
      padding: 5px 12px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1490196078);
      outline: none;
      width: 100%;
      background: #ffffff;
      font-weight: 400;
      font-size: 14px;
      &:focus {
        outline: 1px solid #2c80ff;
      }
    }

    .input-error {
      border-color: red;
    }

    .icon-container {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-error-container {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: fit-content;
      height: -webkit-fill-available;
    }
  }
}

.error-message {
  color: red;
  font-size: 14px;
  height: 24px;
}

.required {
  color: red;
  font-size: 14px;
}
</style>
