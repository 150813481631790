import api from "@/plugins/axios";
import axios from "@/plugins/axios";

export function profile() {
  return api.get("/api/profile").then((response) => response.data);
}

// export function shop(id) {
//   return api.get("/api/shops/" + id).then((response) => response.data);
// }

export function contactUs(payload) {
  return axios.post("/api/contact", payload).then((response) => response.data);
}

export function shopifyCallback(payload) {
  return axios
    .post("/api/shopify-callback", payload)
    .then((response) => response.data);
}

export function shopifyAuth(payload) {
  return axios.post("/shopify_auth", payload).then((response) => response.data);
}

export function changPassword(payload) {
  return axios
    .post("/api/user/change-password", payload)
    .then((response) => response.data);
}

export async function online(id) {
  try {
    await axios.get(process.env.VUE_APP_AI_HOST + "/api/user_online/" + id);
  } catch (error) {
    console.error("Error making request:", error);
  }
}

// export async function offline(id) {
//   try {
//     await axios.get(process.env.VUE_APP_AI_HOST + "/api/user_offline/" + id);
//   } catch (error) {
//     console.error("Error making request:", error);
//   }
// }
