<template>
  <div>
    <!-- title and desc -->
    <TitleSection
      :title="$t('Select Category')"
      :description="$t('category_second_title')"
    />

    <!-- search bar -->
    <div v-if="show" class="search-block">
      <div class="search-bar">
        <!-- search-icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="15"
          fill="gray"
          class="search-icon"
        >
          <path
            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
          />
        </svg>

        <!-- search-input -->
        <input type="text" placeholder="Search..." v-model="searchText" />

        <!-- clear btn -->
        <button class="clear-btn" v-if="searchText" @click="searchText = ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="20"
            height="20"
            fill="#F44336"
          >
            <path
              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
            />
          </svg>
        </button>
      </div>
      <!-- <NormalButton
        color="primary"
        @click="nextStep"
        :disabled="!nextBtnIsActive"
        class="next-btn"
      >
        Next
      </NormalButton> -->
    </div>
    <div v-if="filteredCategories.length || show" class="categories mt-4">
      <v-row style="margin: 0">
        <!-- category card -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="category in filteredCategories"
          :key="category.id"
          class="p-1"
        >
          <div
            class="category text-center cursor-pointer"
            @click="selectItem(category.name)"
            :class="{ selected: selectedItem === category.name }"
          >
            <v-btn
              :loading="loadingStates[category.id]"
              color="#F7DA00"
              @click="favoriteHandler($event, category)"
              class="star-icon"
              variant="text"
            >
              <svg
                v-if="category.is_favorite"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                fill="#F7DA00"
              >
                <path
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                width="20"
                fill="gray"
              >
                <path
                  d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
                />
              </svg>
            </v-btn>
            <div>
              <div class="image">
                <img
                  v-if="category.image"
                  :src="category.image"
                  :alt="category.name"
                />
                <img
                  v-else
                  src="../../assets/images/categories.png"
                  :alt="category.name"
                />
              </div>
              <div class="titel-description">
                <h5>{{ category.name }}</h5>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="!filteredCategories.length && show">
      <h5 class="text-center my-4">No category Found!</h5>
    </div>
    <div class="next-back d-flex justify-space-between" v-if="show">
      <NormalButton
        @click="nextStep"
        :disabled="!nextBtnIsActive"
        class="ml-auto"
        >Next</NormalButton
      >
    </div>
  </div>
</template>
<script>
import store from "@/store";
import NormalButton from "@/components/ui/NormalBtn.vue";
import TitleSection from "../ui/TitleSection.vue";
import { addFav, removeFav } from "@/api/products.js";
export default {
  name: "categoriesComp",
  data() {
    return {
      nextBtnIsActive: false,
      loadingStates: this.categories.reduce((acc, category) => {
        acc[category.id] = false;
        return acc;
      }, {}),
      searchText: "",
    };
  },
  props: {
    selectedItem: String,
    categories: Array,
    show: Boolean,
  },
  components: {
    NormalButton,
    TitleSection,
  },
  methods: {
    favoriteHandler(event, category) {
      event.stopPropagation();
      this.loadingStates[category.id] = true;
      if (!category.is_favorite) {
        addFav({ item_id: category.id })
          .then(() => {
            category.is_favorite = !category.is_favorite;
            this.loadingStates[category.id] = false;
          })
          .catch((error) => {
            this.loadingStates[category.id] = false;
            this.$store.dispatch("showError", error.response.data.message);
          });
      } else {
        removeFav({ item_id: category.id })
          .then(() => {
            category.is_favorite = !category.is_favorite;
            this.loadingStates[category.id] = false;
          })
          .catch((error) => {
            this.loadingStates[category.id] = false;
            this.$store.dispatch("showError", error.response.data.message);
          });
      }
    },
    selectItem(index) {
      this.$emit("selectItem", index);
      this.$store.commit("updateCategory", index);
      this.nextBtnIsActive = true;
    },
    nextStep() {
      store.dispatch("nextStep");
      this.$router.push({ path: "/wizard/upload-images" });
    },
    backStep() {
      store.dispatch("backStep");
    },
  },
  computed: {
    filteredCategories() {
      return this.categories.filter((category) =>
        category.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  border-bottom: 1px solid #0000000f;
  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #000000e0;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--Colors-Neutral-Text-colorTextSecondary, #000000a6);
    width: 90%;
  }
}
.search-block {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0.5rem;
  .search-bar {
    position: relative;
    width: 100%;
    border: 1px solid var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
    border-radius: 4px;
    overflow: hidden;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    .search-icon {
      position: absolute;
      top: 50%;
      left: 0.7rem;
      transform: translateY(-50%);
    }
    input {
      width: 100%;
      outline: none;
      height: 2.5rem;
      padding-left: 2rem;
      font-size: 15px;
      background-color: transparent;
      &::placeholder {
        color: gray;
      }
    }
    .clear-btn {
      position: absolute;
      top: 50%;
      right: 0rem;
      height: 100%;
      padding: 0 0.6rem;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .next-btn {
    height: 2.7rem;
  }
}
.categories {
  max-height: 52vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 992px) {
    max-height: 60vh;
  }
  @media (max-width: 576px) {
    max-height: 60vh;
  }
}
.category {
  border: 1px solid var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
  border-radius: $main-border-radius;
  padding: 10px;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  position: relative;
  &:hover {
    border: 1px solid #c9fafe;
    background: #f4fbfb;
  }
  &.selected {
    background: #f3feff;
    border: 1px solid #4096ff;
  }
  .star-icon {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    padding: 0.7rem;
    height: unset !important;
    min-width: unset !important;
  }
  .image {
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin: 0 auto 10px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .titel-description {
    h5 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #000000e0;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: var(--Colors-Neutral-Text-colorTextSecondary, #000000a6);
    }
  }
}
.next-back {
  margin: 24px 10px;
}
</style>
