<template>
  <v-row justify="center" align="center" :style="`height: ${height}`">
    <v-progress-circular
      indeterminate
      color="#1677FF"
      bg-color="#ffffff"
      :size="82"
      :width="7"
    />
  </v-row>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "100px",
    },
  },
};
</script>

<style></style>
