<template>
  <div class="sidebar">
    <div class="h-100 d-flex flex-column">
      <Steper />
    </div>
  </div>
</template>
<script>
import Steper from "@/components/sidbar/Steper.vue";
export default {
  name: "SidbarLayout",
  components: {
    Steper,
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  width: 264px;
  height: calc(100vh - 64px);
  padding: 24px;
  border-right: 1px solid
    var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
  transition: 0.3s;
  @media (max-width: 768px) {
    & {
      width: 89px;
      padding: 32px 18px 24px;
    }
  }
  @media (max-width: 576px) {
    & {
      display: none;
    }
  }
}
</style>
