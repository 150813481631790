<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BlueCard",
};
</script>

<style scoped lang="scss">
.card {
  padding: 10px;
  background: var(--Colors-Base-Blue-1, #f3feff);
  border: 1px solid var(--Colors-Base-Blue-2, #4096ff);
  border-radius: $main-border-radius;
  height: fit-content;
}
</style>
