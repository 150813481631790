<template>
  <nav class="navbar navbar-expand-xl navbar-light bg-light p-0">
    <div
      class="d-flex justify-space-between align-center p-0"
      :class="{ open: isMenuOpen }"
    >
      <div class="logo-img" :style="isInPreview ? 'border:none' : ''">
        <router-link to="/">
          <img
            src="@/assets/images/logo.svg"
            alt="Logo"
            class="big-screen-logo cursor-pointer"
          />
          <svg
            class="small-screen-logo cursor-pointer"
            width="46"
            height="35"
            viewBox="0 0 46 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.23927 9.88132V6.75421H0V6.11993H3.19295V6.75421H1.95368V9.88132H1.23927Z"
              fill="black"
            />
            <path
              d="M3.79102 6.11993H4.46168L5.84675 8.65703L7.23182 6.11993H7.90249V9.88132H7.1735V7.53599L6.12376 9.45356H5.56974L4.52 7.53599V9.88132H3.79102V6.11993Z"
              fill="black"
            />
            <path
              d="M29.8013 28.8801L27.1916 34.1607H9.3752C5.17625 34.1607 2.39153 29.7651 4.12652 25.9152L13.1805 5.7807H19.0269L12.364 20.6345C10.6291 24.4991 13.4138 28.8801 17.6127 28.8801H29.8013Z"
              fill="#0D51D4"
            />
            <path
              d="M37.0472 14.218L29.8011 28.8801H17.6125C13.4136 28.8801 10.6288 24.4991 12.3638 20.6345L19.0267 5.7807H31.886C36.1724 5.7807 38.9717 10.3386 37.0472 14.218Z"
              fill="#2C80FF"
            />
            <path
              d="M45.2844 8.95206L35.4285 28.88H29.8008L37.0469 14.218C38.9714 10.3386 36.1721 5.78069 31.8856 5.78069H19.0264L21.4029 0.5H40.1232C44.4096 0.5 47.1943 5.07267 45.2844 8.95206Z"
              fill="#29B1FF"
            />
            <g opacity="0.7">
              <path
                d="M31.0258 20.3837H15.79V21.8588H31.0258V20.3837Z"
                fill="white"
              />
            </g>
            <g opacity="0.8">
              <path
                d="M32.3813 16.5928H17.1455V18.0679H32.3813V16.5928Z"
                fill="white"
              />
            </g>
            <path
              d="M24.7564 12.8019H18.2393V14.277H24.7564V12.8019Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>

      <div class="navbar-right-side mx-0 mx-xl-4">
        <button
          @click="toggleMenu"
          class="navbar-toggler minus collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse w-100" id="navbar-default">
          <div
            class="main-menu-routes flex-column flex-md-row w-100"
            :class="isSmallScreen ? 'smallSecrrenStyle' : ''"
            v-show="!isMenuOpen"
          >
            <div class="container-route d-flex flex-column flex-md-row">
              <router-link
                @click="closeNavbar"
                to="/wizard/select-category"
                class="nav-link"
                :class="
                  getActiveClass([
                    '/wizard/select-category',
                    '/wizard/upload-images',
                    '/wizard/listing',
                    '/wizard/review',
                  ])
                "
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3069_3601)">
                    <path
                      opacity="0.4"
                      d="M9.64128 1.33786C8.64959 0.668468 7.35074 0.668467 6.35904 1.33786L2.37534 4.02686C1.4113 4.67759 0.833496 5.76472 0.833496 6.92783V12.3333C0.833496 13.8982 2.10202 15.1667 3.66683 15.1667H12.3335C13.8983 15.1667 15.1668 13.8982 15.1668 12.3333V6.92783C15.1668 5.76472 14.589 4.67759 13.625 4.02686L9.64128 1.33786Z"
                      fill="black"
                    />
                    <path
                      opacity="0.4"
                      d="M10 15.1667V11C10 10.4477 9.55228 10 9 10H7C6.44772 10 6 10.4477 6 11V15.1667H10Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3069_3601">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{{ $t("Home") }}</span>
              </router-link>
              <router-link
                @click="closeNavbar"
                to="/history"
                class="nav-link"
                :class="getActiveClass(['/history'])"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3069_1001)">
                    <path
                      opacity="0.4"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.66651 0.666626H6.61773H6.61772C5.40209 0.666609 4.42225 0.666594 3.65162 0.770203C2.85152 0.877773 2.17785 1.1079 1.64282 1.64294C1.10778 2.17798 0.877651 2.85164 0.770081 3.65174C0.666472 4.42237 0.666486 5.40221 0.666504 6.61784V6.61785V6.66663V9.33329V9.38207V9.38208C0.666486 10.5977 0.666472 11.5775 0.770081 12.3482C0.877651 13.1483 1.10778 13.8219 1.64282 14.357C2.17785 14.892 2.85152 15.1221 3.65162 15.2297C4.42226 15.3333 5.4021 15.3333 6.61775 15.3333H6.66651H9.33317H9.38193C10.5976 15.3333 11.5774 15.3333 12.3481 15.2297C13.1482 15.1221 13.8218 14.892 14.3569 14.357C14.8919 13.8219 15.122 13.1483 15.2296 12.3482C15.3332 11.5775 15.3332 10.5977 15.3332 9.38205V9.33329V6.66663V6.61787C15.3332 5.40223 15.3332 4.42238 15.2296 3.65174C15.122 2.85164 14.8919 2.17798 14.3569 1.64294C13.8218 1.1079 13.1482 0.877773 12.3481 0.770203C11.5774 0.666594 10.5976 0.666609 9.38196 0.666626H9.38195H9.33317H6.66651Z"
                      fill="#E6F4FF"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.5786 5.66408L10.9822 8.45762C10.2446 9.74849 8.40637 9.81181 7.58166 8.57474L7.30839 8.16484C7.03349 7.75248 6.42074 7.77359 6.17486 8.20388L4.57856 10.9974L3.4209 10.3359L5.01721 7.54236C5.75485 6.25149 7.59308 6.18817 8.41779 7.42524L8.69106 7.83514C8.96597 8.2475 9.57871 8.22639 9.82459 7.7961L11.4209 5.00256L12.5786 5.66408Z"
                      fill="#E6F4FF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3069_1001">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{{ $t("My Products") }}</span>
              </router-link>
              <router-link
                @click="closeNavbar"
                to="/setting/my-profile"
                class="nav-link"
                :class="
                  getActiveClass([
                    '/setting',
                    '/setting/',
                    '/my-shop',
                    '/shop-details',
                  ])
                "
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3696_1150)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.00016 5.33398C6.5274 5.33398 5.3335 6.52789 5.3335 8.00065C5.3335 9.47341 6.5274 10.6673 8.00016 10.6673C9.47292 10.6673 10.6668 9.47341 10.6668 8.00065C10.6668 6.52789 9.47292 5.33398 8.00016 5.33398Z"
                      fill="black"
                      fill-opacity="0.88"
                    />
                    <path
                      opacity="0.4"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.20122 0.666992C6.09589 0.666992 5.23433 1.62501 5.3512 2.72414C5.3986 3.16994 4.9032 3.46765 4.5318 3.21655L4.39722 3.12556C3.4838 2.50799 2.2398 2.77688 1.66297 3.71655L0.938363 4.89697C0.352385 5.85155 0.752729 7.10383 1.78378 7.54146C2.18864 7.71331 2.18873 8.28726 1.78372 8.45916C0.752667 8.8968 0.352249 10.1491 0.938254 11.1037L1.66281 12.2841C2.23965 13.2238 3.48369 13.4927 4.39713 12.8751L4.53176 12.784C4.90318 12.5329 5.3986 12.8307 5.3512 13.2765C5.23433 14.3756 6.0959 15.3337 7.20125 15.3337H8.79923C9.90459 15.3337 10.7662 14.3756 10.6493 13.2764C10.6019 12.8306 11.0974 12.5329 11.4688 12.784L11.6035 12.875C12.5169 13.4926 13.761 13.2237 14.3379 12.284L15.0624 11.1037C15.6484 10.1491 15.248 8.8968 14.2169 8.45916C13.8119 8.28726 13.812 7.71331 14.2169 7.54146C15.2479 7.10383 15.6483 5.85155 15.0623 4.89697L14.3377 3.7166C13.7609 2.7769 12.5168 2.50801 11.6034 3.12559L11.4688 3.21661C11.0973 3.46773 10.6019 3.17 10.6493 2.72418C10.7662 1.62502 9.9046 0.666992 8.79926 0.666992H7.20122Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3696_1150">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{{ $t("Settings") }}</span>
              </router-link>
              <router-link
                @click="closeNavbar"
                to="/contact"
                class="nav-link contact"
                :class="getActiveClass(['/contact'])"
              >
                <svg
                  width="16"
                  height="16"
                  fill="red"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3c0 0 0 0 0 0c0 0 0 0 0 0s0 0 0 0s0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 208a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                  />
                </svg>
                <span>{{ $t("Contact Us") }}</span>
              </router-link>
              <UserData class="user-data" @click="closeNavbar" />
            </div>
            <div>
              <button class="disabled-btn">
                Your Credits
                <span>
                  :
                  {{ generationLimitRemain > 0 ? generationLimitRemain : 0 }}
                </span>
              </button>
            </div>
            <!-- <ul class="navbar-nav mb-2 mb-lg-0 d-flex align-items-center"> -->
            <!--              <li class="nav-item">-->
            <!--                <UpgradeBtn />-->
            <!--              </li>-->
            <!-- <div class="position-relative">
                <BlueBase8Btn
                  class="d-flex align-center"
                  @click="toggleLagnMenu"
                >
                  {{ currentLocale }}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3696_7195)">
                      <path
                        opacity="0.4"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99984 0.666992C3.94975 0.666992 0.666504 3.95024 0.666504 8.00033C0.666504 12.0504 3.94975 15.3337 7.99984 15.3337C12.0499 15.3337 15.3332 12.0504 15.3332 8.00033C15.3332 3.95024 12.0499 0.666992 7.99984 0.666992Z"
                        fill="#002C8C"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.7332 3.53387C6.2893 4.64364 5.99984 6.22248 5.99984 8.00033C5.99984 9.77817 6.2893 11.357 6.7332 12.4668C6.9559 13.0235 7.20439 13.4293 7.44622 13.685C7.68672 13.9393 7.87403 14.0003 7.99984 14.0003C8.12565 14.0003 8.31296 13.9393 8.55345 13.685C8.79528 13.4293 9.04378 13.0235 9.26647 12.4668C9.71038 11.357 9.99984 9.77817 9.99984 8.00033C9.99984 6.22248 9.71038 4.64364 9.26647 3.53387C9.04378 2.97714 8.79528 2.57134 8.55345 2.31563C8.31296 2.06133 8.12565 2.00033 7.99984 2.00033C7.87403 2.00033 7.68672 2.06133 7.44622 2.31563C7.20439 2.57134 6.9559 2.97714 6.7332 3.53387ZM6.47748 1.39949C6.87505 0.979088 7.38927 0.666992 7.99984 0.666992C8.6104 0.666992 9.12462 0.979088 9.52219 1.39949C9.91844 1.81848 10.2446 2.38899 10.5044 3.03869C11.0257 4.34178 11.3332 6.09627 11.3332 8.00033C11.3332 9.90438 11.0257 11.6589 10.5044 12.962C10.2446 13.6117 9.91844 14.1822 9.52219 14.6012C9.12462 15.0216 8.6104 15.3337 7.99984 15.3337C7.38927 15.3337 6.87505 15.0216 6.47748 14.6012C6.08124 14.1822 5.75511 13.6117 5.49523 12.962C4.974 11.6589 4.6665 9.90438 4.6665 8.00033C4.6665 6.09627 4.974 4.34178 5.49523 3.03869C5.75511 2.38899 6.08124 1.81848 6.47748 1.39949Z"
                        fill="#002C8C"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.3033 7.33398H0.696399C0.676612 7.55356 0.666504 7.77592 0.666504 8.00065C0.666504 8.22538 0.676612 8.44774 0.696399 8.66732H15.3033C15.3231 8.44774 15.3332 8.22538 15.3332 8.00065C15.3332 7.77592 15.3231 7.55356 15.3033 7.33398Z"
                        fill="#002C8C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3696_7195">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </BlueBase8Btn>
                <DropdownMnue class="dropdown" v-if="isLnagMenuOpen">
                  <div class="dropdown-content">
                    <ul>
                      <li @click="switch_lang('en')">EN</li>
                      <li @click="switch_lang('ar')">AR</li>
                      <li @click="switch_lang('de')">DE</li>
                      <li @click="switch_lang('fa')">FA</li>
                    </ul>
                  </div>
                </DropdownMnue>
              </div> -->
            <!-- <div class="break mx-2"></div> -->
            <!--              <li class="nav-item">-->
            <!--                <BlueBase8Btn class="d-block mr-2">-->
            <!--                  <svg-->
            <!--                    width="16"-->
            <!--                    height="16"-->
            <!--                    viewBox="0 0 16 16"-->
            <!--                    fill="none"-->
            <!--                    xmlns="http://www.w3.org/2000/svg"-->
            <!--                  >-->
            <!--                    <g clip-path="url(#clip0_2186_304)">-->
            <!--                      <path-->
            <!--                        opacity="0.4"-->
            <!--                        fill-rule="evenodd"-->
            <!--                        clip-rule="evenodd"-->
            <!--                        d="M8.00033 0.666626C3.95024 0.666626 0.666992 3.94987 0.666992 7.99996C0.666992 12.05 3.95024 15.3333 8.00033 15.3333C12.0504 15.3333 15.3337 12.05 15.3337 7.99996C15.3337 3.94987 12.0504 0.666626 8.00033 0.666626Z"-->
            <!--                        fill="#002C8C"-->
            <!--                      />-->
            <!--                      <path-->
            <!--                        fill-rule="evenodd"-->
            <!--                        clip-rule="evenodd"-->
            <!--                        d="M7.33366 3.33325V4.66659H8.66699V3.33325H7.33366ZM8.66699 6.66659C8.66699 6.2984 8.36852 5.99992 8.00033 5.99992H6.66699V7.33325H7.33366V11.3333H6.66699V12.6666H8.00033H9.33366V11.3333H8.66699V6.66659Z"-->
            <!--                        fill="#002C8C"-->
            <!--                      />-->
            <!--                    </g>-->
            <!--                    <defs>-->
            <!--                      <clipPath id="clip0_2186_304">-->
            <!--                        <rect width="16" height="16" fill="white" />-->
            <!--                      </clipPath>-->
            <!--                    </defs>-->
            <!--                  </svg>-->
            <!--                </BlueBase8Btn>-->
            <!--              </li>-->
            <!-- </ul> -->
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div v-if="isLoading" class="loader"></div>
</template>
<script>
import { profile } from "@/api/profile";
import BlueBase8Btn from "@/components/ui/BlueBase8Btn.vue";
import UpgradeBtn from "@/components/ui/UpgradeBtn.vue";
import index, { mapGetters } from "vuex";
import DropdownMnue from "@/components/ui/DropdownMnue.vue";
import UserData from "@/components/sidbar/UserData.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "NavbarLayout",
  data() {
    return {
      isMenuOpen: false,
      isLnagMenuOpen: false,
      isSmallScreen: false,
      showModal: false,
      dataGenerated: [],
      isLoading: false,
      generation_limit_remain: "",
    };
  },
  components: { UserData, BlueBase8Btn, UpgradeBtn, DropdownMnue },
  computed: {
    ...mapState(["generationLimitRemain"]),
    index() {
      return index;
    },
    currentLocale() {
      return this.$i18n.locale.toUpperCase();
    },
    ...mapGetters(["isInPreview"]),
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapMutations(["setGenerationLimitRemain"]),
    switch_lang(lang) {
      this.isLoading = true;
      this.$i18n.locale = lang;
      this.$store.commit("setLocale", lang);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      // const navbarDefault = document.getElementById("navbar-default");
      // if (navbarDefault) {
      //   navbarDefault.classList.toggle("showMenuInSmScreen", this.isMenuOpen);
      // }
    },
    toggleLagnMenu() {
      this.isLnagMenuOpen = !this.isLnagMenuOpen;
    },
    closeNavbar() {
      if (window.innerWidth < 576) {
        const toggleNavbar =
          document.getElementsByClassName("navbar-toggler")[0];
        if (toggleNavbar) {
          toggleNavbar.click();
        }
      }
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 1199;
      if (this.isSmallScreen) {
        this.isMenuOpen = true;
      } else {
        this.isMenuOpen = false;
      }
    },
    getActiveClass(paths) {
      return paths.some((path) => this.currentPath.startsWith(path))
        ? "active"
        : "";
    },
    onShowModal() {
      this.showModal = true;
    },
    goToListeProductPage() {
      this.$router.push({ path: "/wizard/listing" });
      this.showModal = false;
    },
  },
  mounted() {
    this.checkScreenSize();
    profile()
      .then((response) => {
        this.setGenerationLimitRemain(response.generation_limit_remain);
      })
      .catch((error) => {
        this.$store.dispatch("showError", error.response.data.message);
      });
    window.addEventListener("resize", this.checkScreenSize);
    let dataGeneratedFromLocalStorge = localStorage.getItem(
      "respons-data-after-uplade-images"
    );

    this.dataGenerated = JSON.parse(dataGeneratedFromLocalStorge);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped lang="scss">
.open::after {
  width: 100% !important;
}
.navbar > div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 64px;
  margin: 0;
  border-bottom: 1px solid
    var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
  background: #fff;

  &::after {
    content: "";
    z-index: 100;
    transition: all 0.3s linear;
    position: fixed;
    top: 60px;
    left: 0;
    width: 264px;
    height: 4px;
    background-color: var(--Colors-Base-Blue-2, #4096ff);
    @media (max-width: 768px) {
      width: 89px;
    }
    @media (max-width: 576px) {
      display: none;
    }
  }

  .logo-img {
    height: 64px;
    padding: 15px 80px 15px 24px;
    border-right: 1px solid
      var(--Colors-Neutral-Border-colorBorderSecondary, #f0f0f0);
    z-index: 3;
    @media (max-width: 576px) {
      border: none;
    }
    .big-screen-logo {
      display: block;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .small-screen-logo {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
    }
    @media (max-width: 768px) {
      padding: 15px 21px 15px 21px;
    }
  }
}

.navbar-right-side {
  width: calc(100% - 265px);
  .navbar-toggler {
    box-shadow: none !important;
    border: none;
    background: transparent !important;
    @media (min-width: 1199px) {
      display: none;
    }
  }

  .navbar-toggler .icon-bar {
    background-color: var(--Colors-Base-Blue-6, #1677ff);
    width: 30px;
    height: 3px;
    border-radius: 6px;
    display: block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  /* ANIMATED MINUS */
  .navbar-toggler.minus .icon-bar:nth-of-type(1) {
    transform: translateY(3px);
  }

  .navbar-toggler.minus .icon-bar:nth-of-type(2) {
    transform: translateY(0px);
  }

  .navbar-toggler.minus .icon-bar:nth-of-type(3) {
    transform: translateY(-3px);
  }

  .navbar-toggler.minus.collapsed .icon-bar:nth-of-type(1) {
    transform: translate3d(0, 10px, 0) rotate(0deg);
  }

  .navbar-toggler.minus.collapsed .icon-bar:nth-of-type(3) {
    transform: translate3d(0, -10px, 0) rotate(0deg);
  }
  /* END ANIMATED MINUS */
}

#navbar-default {
  width: 100%;
  padding: 0 1rem;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .container-route {
      display: flex;
      gap: 16px;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 5px 15px;
        box-shadow: 0px 2px 0px 0px #00000005;
        border: 1px solid
          var(--Colors-Brand-Control-controlItemBgActiveDisabled, #00000026);
        border-radius: 8px;
        background: #0000000f;
        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #000000;
        }
        svg path {
          fill: black;
        }
        &.contact svg path {
          fill: #909090;
        }
        &.contact {
          position: relative;
          overflow: hidden;
          span {
            font-weight: 600;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: -51px;
            background: #29b1ff;
            width: 114px;
            height: 35px;
            opacity: 0.5;
            transform: rotate(45deg);
            transition: all 0.3s linear;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -70px;
            background: #29b1ff;
            width: 114px;
            height: 25px;
            opacity: 0.5;
            transform: rotate(45deg);
            transition: all 0.3s linear;
          }
          &:hover::after {
            width: 198px;
            height: 95px;
          }
          &:hover::before {
            width: 130px;
            height: 50px;
          }
        }
        &.active {
          box-shadow: 0px 2px 0px 0px #00000005;
          border: 1px solid var(--Colors-Base-Blue-6, $primary-color);
          background: var(--Colors-Base-Blue-6, $primary-color);
          span {
            color: #fff;
            font-weight: 400;
          }
          svg path {
            fill: #e6f4ff;
          }
          &.active::after,
          &.active::before {
            opacity: 0 !important;
          }
        }
      }
      .user-data {
        display: none;
        .profile-setting {
          display: none !important;
        }
        @media (max-width: 576px) {
          display: block;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  #navbar-default {
    & > div {
      z-index: 99;
      text-align: right;
      gap: 24px;
      padding: 24px;
      position: absolute;
      left: 0;
      top: 64px;
      background: white;
      gap: 16px;
      border-bottom: 1px solid #f0f0f0;
      padding: 24px;
      box-shadow: 2px 2px 2px #f0f0f0;
    }
  }
  .navbar-right-side {
    padding-right: 20px;
    text-align: right;
  }
}
// nav > span .smallSecrrenStyle {
//   z-index: 99;
//   text-align: right;
//   gap: 24px;
//   padding: 24px;
//   .main-menu-routes {
//     position: absolute;
//     left: 0;
//     top: 64px;
//     background: white;
//     gap: 16px;
//     padding-top: 16px;
//     border-bottom: 1px solid #f0f0f0;
//     padding: 24px;
//   }
//   .container-route {
//     gap: 16px;
//     margin-left: 0;
//   }
// }
// .showMenuInSmScreen {
//   display: block;
//   position: absolute;
//   top: 64px;
//   left: 0;
//   background: white;
//   z-index: 4;
// }
.navbar-nav {
  .nav-item {
    .products-modal {
      .products-lsit {
        display: block;
        position: absolute;
        right: 70px;
        top: 50px;
        width: 33vw;
        height: 80vh;
        background: #ffffff;
        z-index: 999;
        box-shadow: 0px 2px 4px 0px #00000005, 0px 1px 6px -1px #00000005,
          0px 1px 2px 0px #00000008;
        border-radius: $main-border-radius;
        overflow-y: scroll;
        scrollbar-width: none;
        padding: 32px;
      }
    }
    .background-modal {
      background: rgba(0, 0, 0, 0.258);
      width: 100vw;
      height: 100vh;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 998;
    }
  }
}
.break {
  height: 12px;
  width: 1px;
  background: #0000000f;
}
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s;
// }
// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }

.dropdown {
  position: absolute;
  top: calc(100% + 18px);
  left: 50%;
  transform: translate(-50%);
  .dropdown-content {
    padding: 4px;

    ul {
      list-style: none;
      padding: 8px 0;
      margin: 0;
    }

    li {
      padding: 5px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #000e;
      border-radius: 4px;
    }

    li:hover {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader:after {
  content: "";
  width: 100px;
  height: 100px;
  border: 8px solid $primary-color;
  border-top: 8px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.disabled-btn {
  background: rgba(0, 0, 0, 0.0392156863);
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 0.4);
  cursor: no-drop;
  border-radius: 8px;
  padding: 7px 20px;
  transition: 0.3s all;
  font-size: 14px;
}
</style>
