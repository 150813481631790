<template>
  <div class="review">
    <div class="products-list">
      <div v-if="!isLoading">
        <v-row class="products row" v-if="hasData">
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-for="product in $store.state.dataGenerated"
            :key="product.id"
          >
            <product
              :id="product.id"
              :name="product.json_data ? product.json_data : null"
              :url="product.image"
              :product="product"
              @onDeleteProduct="onDeleteProduct(product.id)"
              :justOneProduct="
                $store.state.dataGenerated.length > 1 ? false : true
              "
            />
          </v-col>
        </v-row>
        <div class="listapro-table w-100" v-else>
          <table>
            <tr class="">
              <td class="empty-archive" colspan="6">
                <EmptyArchive />
                <h1>No product archive added</h1>
                <p>
                  No products have been recently uploaded. Please add products
                  or you can view
                  <a href="/history">My Products History</a>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div class="go-to-listing" v-if="$store.state.dataGenerated.length > 1">
          <button @click="listProduct" v-if="hasData">List Your Product</button>
        </div>
      </div>
      <v-container v-else-if="isLoading" class="position-relative">
        <ListaProgressCircular height="450px" />
      </v-container>
    </div>
    <div class="dialog">
      <v-dialog v-model="dialogVisible" max-width="400" persistent>
        <v-card style="border-radius: 10px" class="p-4">
          <div class="d-flex align-center pa-4">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM10.25 5.9375C10.25 5.83437 10.3344 5.75 10.4375 5.75H11.5625C11.6656 5.75 11.75 5.83437 11.75 5.9375V12.3125C11.75 12.4156 11.6656 12.5 11.5625 12.5H10.4375C10.3344 12.5 10.25 12.4156 10.25 12.3125V5.9375ZM11 16.25C10.7056 16.244 10.4253 16.1228 10.2192 15.9125C10.0132 15.7022 9.89773 15.4195 9.89773 15.125C9.89773 14.8305 10.0132 14.5478 10.2192 14.3375C10.4253 14.1272 10.7056 14.006 11 14C11.2944 14.006 11.5747 14.1272 11.7808 14.3375C11.9868 14.5478 12.1023 14.8305 12.1023 15.125C12.1023 15.4195 11.9868 15.7022 11.7808 15.9125C11.5747 16.1228 11.2944 16.244 11 16.25Z"
                fill="#FAAD14"
              />
            </svg>
            <div class="ms-2">
              <div class="title">Are you sure?</div>
              <div class="text">You want to dismiss changes you made</div>
            </div>
          </div>
          <template v-slot:actions>
            <div class="buttons mt-3">
              <button class="cancel-dialog" @click="onHideDialog">
                Cancel
              </button>
              <button class="confirm-delete" @click="onConfirmDialog">
                Yes
              </button>
            </div>
          </template>
        </v-card>
      </v-dialog>

      <!-- dialog for Product deleted Successfully msg -->
      <v-dialog v-model="isProductDeletedDialog" max-width="520">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn v-bind="activatorProps" class="dialog-btn"></v-btn>
        </template>
        <v-card style="border-radius: 10px; padding: 20px 24px">
          <div class="d-flex align-center justify-center">
            <div>
              <div class="dialog-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                    fill="#52C41A"
                  />
                </svg>
              </div>
              <div class="dialog-success-title">
                Product Deleted Successfully
              </div>
              <div class="dialog-success-description">
                All changes have been saved successfully.
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Product from "@/components/Review/Product.vue";
import ListaProgressCircular from "@/components/ui/progress/ListaProgressCircular.vue";
import { deleteProduct } from "@/api/products";
import { defineAsyncComponent } from "vue";
import axios from "axios";

export default {
  name: "ReviewPage",
  data() {
    return {
      productsData: [],
      activeProduct: {},
      displayed: "status",
      activeProductId: null,
      dataGenerated: [],
      modifiedData: false,
      whereWereYou: "edit",
      ids: [],
      canSumbit: true,
      loading: false,
      productStatusval: null,
      productSelected: null,
      editHeaderFixed: false,
      AiAvailable: true,
      isLoading: false,
      isFiltter: "all",
      dialogVisible: false,
      hasData: false,
      isProductDeletedDialog: false,
    };
  },
  components: {
    Product,
    ListaProgressCircular,
    EmptyArchive: defineAsyncComponent(() =>
      import("@/components/ui/svgs/EmptyArchive.vue")
    ),
  },
  computed: {
    ...mapGetters(["user"]),
  },
  ...mapMutations([
    "setListOfProducts",
    "setIsListOfProductsDisabled",
    "setDataGenerated",
  ]),
  methods: {
    ...mapMutations([
      "setHideInfoCard",
      "setHideUserDataCard",
      "setIsInPreview",
      "setCurrentStep",
      "setIsListOfProductsDisabled",
      "setListOfProducts",
      "setDataGenerated",
    ]),
    listProduct() {
      this.$router.push({ path: "/wizard/listing" });
    },
    onDeleteProduct(id) {
      deleteProduct(id)
        .then(() => {
          this.isLoading = false;
          let dataGeneratedFromLocalStorge = this.$store.state.dataGenerated;

          if (dataGeneratedFromLocalStorge) {
            for (let i = 0; i < dataGeneratedFromLocalStorge.length; i++) {
              if (dataGeneratedFromLocalStorge[i].id == id) {
                dataGeneratedFromLocalStorge.splice(i, 1);
                break;
              }
            }
            this.setListOfProducts(dataGeneratedFromLocalStorge);
            this.setDataGenerated(dataGeneratedFromLocalStorge);
            this.isProductDeletedDialog = true;
            if (this.$store.state.dataGenerated.length) {
              this.hasData = true;
            } else {
              this.hasData = false;
              this.setIsListOfProductsDisabled(false);
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error deleteing Products:", error);
          this.$store.dispatch("showError", error.response.data.message);
        });
    },
    checkAIServer() {
      axios
        .get("https://ai-cp.sellenvo.com/api/ai_server_status")
        .then((response) => {
          this.AiAvailable = response.data.is_available;
          if (!response.data.is_available) {
            this.productStatusval = "success";
          }
        })
        .catch((error) => {
          console.error("error", error);
          this.$store.dispatch("showError", error.response.data.message);
        });
    },
    filter(type) {
      this.isFiltter = type;

      let dataGeneratedFromLocalStorge = this.$store.state.dataGenerated;

      console.log(dataGeneratedFromLocalStorge);

      if (type == "all") {
        return this.dataGenerated;
      } else {
        this.dataGenerated = this.dataGenerated.filter((item) => {
          console.log("item.status", item.status);
          return item.status == type;
        });
      }
    },
    onHideDialog() {
      this.dialogVisible = false;
    },
    onConfirmDialog() {
      this.dialogVisible = false;
    },
  },
  watch: {
    "$store.state.dataGenerated.length"(newLength) {
      if (newLength > 0) {
        this.hasData = true;
      } else {
        this.hasData = false;
      }
    },
  },
  mounted() {
    this.checkAIServer();
    this.setHideUserDataCard(true);
    this.setIsInPreview(true);
    this.setHideInfoCard(false);
    this.setCurrentStep(2);
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        this.editHeaderFixed = true;
      } else {
        this.editHeaderFixed = false;
      }
    });

    if (this.$store.state.dataGenerated.length) {
      this.hasData = true;
    } else {
      this.hasData = false;
    }
  },
  unmounted() {
    this.setHideUserDataCard(false);
    this.setIsInPreview(false);
  },
};
</script>

<style scoped lang="scss">
.review {
  color: #000000e0;
  .products-list {
    overflow-y: scroll;
    scrollbar-width: none;
    width: 100%;
    .products {
      margin: 0;
    }
  }
  .product-uploaded-num {
    background: #f3feff;
    border-bottom: 1px solid #4096ff;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #000000e0;
    width: 100%;
  }
}
.review-header {
  background: #f3feff;
  border-bottom: 1px solid #4096ff;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #000000e0;
}
.products-and-review {
  overflow-y: scroll;
  scrollbar-width: none;
}

.product {
  .image {
    min-width: 152px;
    height: 152px;
    border-radius: 6px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .details {
    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #00000073;
    }
    .status {
      box-shadow: 0px 2px 5px 1px #bfbfbf4a;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding: 9px 12px;
      height: 40px;
    }
  }
}
.left-side-content {
  height: calc(100vh - 152px);
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 24px 24px 24px 32px;
}
@media (max-width: 1200px) {
  .left-side-content {
    height: auto;
  }
}
.go-to-listing {
  width: 100%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 40px;
  button {
    padding: 8px 16px;
    border-radius: $main-border-radius;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    background-color: #2c80ff;
    box-shadow: 0px 2px 0px 0px #0591ff1a;
    box-shadow: 0px 2px 0px 0px #0591ff1a;
    margin-top: 24px;
  }
}
</style>
