<template>
  <div class="title" :style="!withUnderLine ? 'border:none' : ''">
    <h4>{{ title }}</h4>
    <p class="" v-if="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    withUnderLine: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  border-bottom: 1px solid #0000000f;
  padding-bottom: 16px;
  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #000000e0;
    margin-bottom: 0px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--Colors-Neutral-Text-colorTextSecondary, #000000a6);
  }
}
</style>
