import axios from "../plugins/axios";

export function generate(payload) {
  return axios
    .post("/api/playground/upload-images", payload)
    .then((response) => response.data);
}
export function getResponseAi(identifier, status) {
  return axios
    .get(`/api/playground/get-response/${identifier}`, {
      params: {
        status: status,
      },
    })
    .then((response) => response.data);
}

export function getKeywords(ai_token) {
  return axios
    .get(`/api/products/${ai_token}/get-keywords`)
    .then((response) => response.data);
}

export function generateUIEnhancement(ai_token, keywords) {
  return axios
    .post(`/api/products/${ai_token}/enhance`, keywords)
    .then((response) => response.data);
}

export function listProduct(payload) {
  return axios
    .post(`/api/playground/list`, payload)
    .then((response) => response.data);
}

export function updateProduct(id, payload) {
  return axios
    .post(`/api/playground/update/${id}`, payload)
    .then((response) => response.data);
}
export function updateProductByAI(id, payload) {
  return axios
    .post(`/api/playground/update-by-ai/${id}`, payload)
    .then((response) => response.data);
}
export function getCategories(key = null) {
  return axios
    .get("/api/categories", {
      params: {
        key: key,
      },
    })
    .then((response) => response.data);
}

export function getProductDetails(id) {
  return axios
    .get(`/api/playground/get-product-details/${id}`)
    .then((response) => response.data);
}
export function getVendors() {
  return axios.get(`/api/shopify/vendors`).then((response) => response.data);
}

export function getCollections() {
  return axios
    .get(`/api/shopify/collections`)
    .then((response) => response.data);
}

// export function getReviewProduct(payload) {
//   return axios
//     .post("/api/playground/get-review-products", payload)
//     .then((response) => response.data);
// }
export function history(page, itemsPerPage, filter) {
  return axios
    .get(`/api/history?page=${page}&limit=${itemsPerPage}&filter=${filter}`)
    .then((response) => response.data);
}
export function deleteProduct(id) {
  return axios
    .delete(`/api/playground/delete/${id}`)
    .then((response) => response.data);
}
export function ExportCsv(idArray) {
  return axios
    .post("/api/playground/export/csv", idArray)
    .then((response) => response.data);
}
export function generateData(payload) {
  return axios
    .post("/api/playground/generate", payload)
    .then((response) => response.data);
}
export function generationRequest(payload) {
  return axios
    .post("/api/playground/store-generation-request", payload)
    .then((response) => response.data);
}
export function regenerateProduct(id) {
  return axios
    .get(`/api/playground/regenerate-product/${id}`)
    .then((response) => response.data);
}
export function addFav(payload) {
  return axios
    .post("/api/categories/favorites/add", payload)
    .then((response) => response.data);
}
export function removeFav(payload) {
  return axios
    .post("/api/categories/favorites/remove", payload)
    .then((response) => response.data);
}
