<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      message: "there was error!",
      color: "error",
      timeout: 3000,
    };
  },
  computed: {
    snackbarMessage() {
      return this.$store.state.snackbarMessage;
    },
    snackbarVisible() {
      return this.$store.state.snackbarVisible;
    },
    snackbarColor() {
      return this.$store.state.snackbarColor;
    },
  },
  watch: {
    snackbarMessage(val) {
      this.message = val;
      this.snackbar = this.snackbarVisible;
      this.color = this.snackbarColor;
    },
  },
};
</script>
