<template>
  <v-app>
    <router-view />
    <listaproErrorMsg />
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import listaproErrorMsg from "./components/ui/listaproErrorMsg.vue";

export default {
  components: {
    listaproErrorMsg,
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
