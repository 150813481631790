<template>
  <BlueCard class="">
    <section class="my-container">
      <div class="instructions white-card text-center">
        <div class="icon"><InstructionsIcon /></div>
        <header>
          <h2 class="title">How to use Listapro?</h2>
        </header>
        <ol class="mb-0">
          <li>
            <p class="instruction">
              <b>1. Select Product Category</b> Begin by choosing the
              appropriate category for your product, then click "Next."
            </p>
          </li>
          <li>
            <p class="instruction">
              <b>2. Upload Product Images</b> Upload photos of your products,
              ensuring each photo represents a single product for clarity.
            </p>
            <p class="instruction">
              <b> 3. Customize Your Listings (Optional)</b> You have the option
              to add descriptions and adjust the category for each photo. Once
              ready, click the "Build Product Information" button to proceed.
            </p>
          </li>
          <li>
            <p class="instruction">
              <b>4. Track Your Upload Progress</b> A progress menu will appear,
              allowing you to monitor the processing of your uploaded images.
            </p>
          </li>
          <li>
            <p class="instruction">
              <b>5. Review and Edit Listings</b> As images are processed, they
              will receive a success status. You can then review or edit the
              product details and attributes. Additional images can also be
              uploaded at this stage if needed.
            </p>
          </li>
          <li>
            <p class="instruction">
              <b> 6. Publish to Your Store After</b> finalizing your edits,
              click "List Your Product" to publish the product to your Shopify
              store.
            </p>
          </li>
        </ol>
        <p class="contact-support">
          Need Assistance?
          <router-link to="/contact"> Contact Support </router-link>
        </p>
      </div>
    </section>
  </BlueCard>
</template>

<script>
import BlueCard from "@/components/ui/BlueCard.vue";
import { defineAsyncComponent } from "vue";
export default {
  name: "InfoCard",
  data() {
    return {
      mailtoLink:
        "mailto:support@sellenvo.com?subject=Support%20Request&body=Please%20describe%20your%20issue%20here.",
    };
  },
  components: {
    BlueCard,
    InstructionsIcon: defineAsyncComponent(() =>
      import("./ui/svgs/InstructionsIcon.vue")
    ),
  },
};
</script>

<style scoped lang="scss">
.my-container {
  padding: 8px;
}
ol {
  counter-reset: item;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-content: left;
  height: 40vh;
  overflow-y: scroll;
}
.white-card {
  background-color: white;
  border-radius: $main-border-radius;
  border: $main-border-1px;
}

.instructions {
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding: 16px 24px;
    border-bottom: $main-border-1px;
  }
  ol li {
    margin-bottom: 0;
  }
  .instruction {
    text-align: left;
    border-bottom: $main-border-1px;
    padding: 10px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    line-height: 20px;
    text-align: justify !important;
  }
  .contact-support {
    padding: 12px 24px;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #000000e0;
    a {
      color: $primary-color;
      cursor: pointer;
    }
  }
}

ol::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

ol::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
  border-radius: 10px;
}

ol::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;
}

ol::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}
</style>
