<template>
  <div class="my-dropdown"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.my-dropdown {
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px #0000000d, 0px 3px 6px -4px #0000001f,
    0px 6px 16px 0px #00000014;
  z-index: 5;
  border-radius: $main-border-radius;
  &::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
}
</style>
